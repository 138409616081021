import React, {DependencyList, ReactNode, RefObject, useCallback, useEffect, useRef} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import dayjs, {Dayjs} from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import CSV from 'papaparse';
import {saveAs} from 'file-saver';
// TODO check can we replace from xlsx library to exceljs
import * as XLSX from 'xlsx';
import dompurify from 'dompurify';
import useQuery from './useQuery';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import en from './GlobalLocalization/EN';
import uk from './GlobalLocalization/UK';
import {ReactComponent as Lang_uk} from 'assets/icons/language_uk.svg';
import {ReactComponent as Lang_en} from 'assets/icons/language_en.svg';
import {ReactComponent as Cash} from 'assets/icons/cash.svg';
import {ReactComponent as Cashless} from 'assets/icons/cashless.svg';
import {ReactComponent as Card} from 'assets/icons/card.svg';

export interface UserInfoProps {
  network?: string;
  name?: string;
  surname?: string;
  code?: string;
  isCodeWrong?: boolean;
  telephone?: string;
  telephoneMask?: string;
  isTelephoneWrong?: boolean;
  telError?: string;
  emailError?: string;
  isTelError?: boolean;
  isEmailError?: boolean;
  email?: string;
  password?: string;
  confirmPassword?: string;
  isPasswordWrong?: boolean;
  isEmailWrong?: boolean;
  isConfirmPasswordWrong?: boolean;
  isStepDisable?: boolean;
  isSendDisable?: boolean;
  isError?: boolean;
  isButtonDisabled?: boolean;
  countDown?: number;
}

export interface LocalizationObjProps {
  [key: string]: {
    [key: string]: string;
  };
}

export interface PlaceProps {
  value: string;
  label: string;
  icon: string;
}

interface PlacePageLayoutType {
  isLoading: boolean;
  isVisible: boolean;
  choosingPlace: string;
  placesOptions: PlaceProps[];
}

export const useDebounceEffect = (fnc: () => void, delay: number, deps?: DependencyList) => {
  const ref = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      fnc();
      clearTimeout(ref.current);
    }, delay);
  }, [fnc, deps, delay]);
};

const localizationObj = {en, uk} as LocalizationObjProps;
export const globalLocalization = (language: string) => localizationObj[language];

/**
 * (?=.*\d)        contain at least one digit
 * (?=.*[a-z])     contain should contain at least one lower case
 * (?=.*[A-Z])     contain should contain at least one upper case
 * (?=.*\W)        contain special symbol
 * {8,}            contain at least 8 symbols
 */
export const authPasswordValidation = (value: string) =>
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/.test(value.trim());

// 0 - 100
export const discountValidation = (value: string) => /^(0|[1-9][0-9]?|100)$/.test(value.trim());

// number 1-10 symbols
export const codeValidation = (value: string) => (value.length !== 0 ? /^\d{1,10}$/.test(value) : true);

export const telephoneValidation = (value: string) => value.replace(/\D/g, '').split('_')[0].length === 12;

export const smsCodeValidation = (value: string) => value.split('_')[0].length === 6;

export const isFractionalValue = (value: string) =>
  /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/.test(value.trim());

export const isIntegerValue = (value: string) => /^[1-9]\d*$/.test(value.trim());

export const emailValidation = (value: string) =>
  !value.includes(' ') &&
  /[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?/.test(
    value.trim()
  );

export const websiteValidation = (value: string) =>
  !value.includes(' ') &&
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
    value.trim()
  );

export const languageOptions = [
  {label: 'Українська', value: 'uk', img: <BaseIcon icon={<Lang_uk />} />},
  {label: 'English', value: 'en', img: <BaseIcon icon={<Lang_en />} />},
];

export const ISOFormat = 'YYYY-MM-DDTHH:mm:ss';

export const stateReducer = (state: any, action: any) => ({...state, ...action});

export const masksParams = {
  zeroStartVal: '+0_ (___) ___ - __ - __',
  zeroStartMask: '+38 (0__) ___ - __ - __',
  ukTelMask: '+38 (099) 999 - 99 - 99',
  anyTypeMask: '+99 (999) 999 - 99 - 99',
  codeSmsMask: '999999',
};

export const itemPerRowOptions = [
  {
    value: 25,
    label: 25,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const DEFAULT_TIMER = 30;

const Icon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    width: 0.85rem;
    height: 0.85rem;
  }
`;

export const getCaseType = (type: string) => {
  const target: {[key: string]: ReactNode} = {
    ['Безготівкова']: <Icon icon={<Cashless />} />,
    ['Готівка']: <Icon icon={<Cash />} />,
    ['Карта']: <Icon icon={<Card />} />,
  };
  return target[type] || '';
};

export const timeModify = (hours: string, minutes: string) =>
  `${hours.length === 1 ? '0' : ''}${hours}:${minutes.length === 1 ? '0' : ''}${minutes}`;

export const timeDayjsModify = (value: string) => {
  const [hour, minutes] = value.split(':');
  return hour === 'NaN' || hour === 'null' || minutes === 'NaN'
    ? null
    : dayjs().set('hour', +hour).set('minutes', +minutes);
};

export const SanitizeHtml = (html: string) =>
  dompurify.sanitize(html, {
    ALLOWED_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  });

export const cleanMaskValue = (value: string) => value.replace(/[_]/gi, '');

// Base func to request for auth api
export const auth = axios.create({
  baseURL: `https://${process.env.REACT_APP_USER_API}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Func with interceptors on request
const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  // Push token to request interceptor
  instance.interceptors.request.use(
    (config) => {
      if (localStorage.getItem('BearerToken')) {
        config.headers.Authorization = localStorage.getItem('BearerToken');
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Refresh token interceptor
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        response: {status},
        config,
      } = error;

      if (status === 401) {
        if (localStorage.getItem('BearerToken')) {
          return auth
            .post('/auth/token/refresh', {refresh_token: localStorage.getItem('RefreshToken')})
            .then(({data: {token, refresh_token}}) => {
              localStorage.setItem('BearerToken', token);
              localStorage.setItem('RefreshToken', refresh_token);
              config.headers.Authorization = token;
              return axios.request(config);
            })
            .catch(() => {
              localStorage.clear();
              return Promise.reject(error);
            });
        } else {
          localStorage.clear();
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const place = createAxiosInstance(`https://${process.env.REACT_APP_PLACE_API}`);
export const user = createAxiosInstance(`https://${process.env.REACT_APP_USER_API}`);
export const order = createAxiosInstance(`https://${process.env.REACT_APP_ORDER_API}`);

export interface OptionsProps {
  value: string | number;
  label: string | number | ReactNode;
  img?: ReactNode;
}

export const useExportFiles = () => {
  const [, isTablet] = useQuery();

  const exportPDF = useCallback(
    async (ref: RefObject<HTMLDivElement>, filename: string) => {
      const page = ref.current;
      if (page) {
        const padding = 20;
        const canvas = await html2canvas(page, {
          scale: 3,
          ignoreElements: (element) => {
            return element instanceof HTMLInputElement && element.placeholder === 'DD.MM.YYYY hh:mm';
          },
        });
        const imgData = canvas.toDataURL('image/jpeg', 0.5);

        const orientation = isTablet ? 'l' : 'p';

        const pdf = new jsPDF({
          orientation,
          unit: 'mm',
          format: isTablet ? 'a5' : 'a4',
        });

        const pdfWidth = pdf.internal.pageSize.getWidth() - padding * 2;
        const pageHeight = pdf.internal.pageSize.getHeight() - padding * 2;
        const imgHeight = (canvas.height * pdfWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        while (heightLeft > 0) {
          pdf.addImage(imgData, 'JPEG', padding, padding + position, pdfWidth, imgHeight, undefined, 'FAST');
          heightLeft -= pageHeight;

          if (heightLeft > 0) {
            position -= pageHeight;
            pdf.addPage();
          }
        }

        pdf.save(`${filename}.pdf`);
      }
    },
    [isTablet]
  );

  const exportCSV = useCallback((data: unknown[], filename: string) => {
    const csv = CSV.unparse(data);
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    saveAs(blob, `${filename}.csv`);
  }, []);

  const exportJSON = useCallback((data: unknown[], filename: string) => {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], {type: 'application/json;charset=utf-8;'});
    saveAs(blob, `${filename}.json`);
  }, []);

  const exportExcel = useCallback((data: unknown[], filename: string) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(blob, `${filename}.xlsx`);
  }, []);

  return {exportPDF, exportCSV, exportJSON, exportExcel};
};

export const withConfigTime = (date: Dayjs, hours: string | null, minutes: string | null) =>
  hours !== null && minutes !== null ? date.hour(parseInt(hours)).minute(parseInt(minutes)) : date;

export const hasValuesChanged = (initialValues: any, currentValues: any): boolean =>
  initialValues &&
  currentValues &&
  Object.keys(initialValues).some((key) =>
    Array.isArray(initialValues[key]) && Array.isArray(currentValues[key])
      ? initialValues[key].length !== currentValues[key].length ||
        initialValues[key].some((val: any, i: number) => val !== currentValues[key][i])
      : initialValues[key] !== currentValues[key]
  );

export const initPlacePageLayoutState: PlacePageLayoutType = {
  isLoading: true,
  isVisible: false,
  choosingPlace: '',
  placesOptions: [],
};
